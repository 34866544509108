function calcIt(){
    const calc = $('.calculator-wrapper')
    const size = calc.find('.brick-height .tab.active').index()
    const type = calc.find('.select-type .tab.active').index()
    const density = parseInt(calc.find('.brick-height .tab.active').data('volume'))
    const volume = parseInt($('#volume').val()) | 0
    const placing = parseInt(calc.find('.brick-type .tab.active').data('count').split(';')[size])
    const wall_x = parseInt($('#wall_x').val()) | 0
    const wall_z = parseInt($('#wall_z').val()) | 0
    const wall = wall_x * wall_z
    const height = parseInt($('#height').val()) | 0
    const aperture_width = parseInt($('#aperture_width').val()) | 0
    const aperture_height = parseInt($('#aperture_height').val()) | 0
    const area = Math.max(0, (wall * height) - (aperture_width * aperture_height))
    const total = Math.round(area * placing).toLocaleString()
    const volumeTotal = Math.round(volume * density).toLocaleString()
    const totalElem = calc.find('.total')

    if(type){
        totalElem.text(volumeTotal + ' шт.')
    }else{
        totalElem.text(total + ' шт.')
    }
}

$('.brick-type .tab, .brick-height .tab').click(function(){
    const elem = $(this)
    const siblings = elem.siblings()

    elem.addClass('active')
    siblings.removeClass('active')
    calcIt()
})

$('.select-type .tab').click(function(){
    const elem = $(this)
    const index = elem.index()
    const form = elem.parents('.calculator-wrapper')
    const wrappers = form.find('.inputs')

    elem.siblings().removeClass('active')
    elem.addClass('active')

    wrappers.removeClass('active')
    wrappers.eq(index).addClass('active')
    calcIt()
})

$(document).on('input', '#wall_x, #wall_z, #height, #aperture_width, #aperture_height, #volume', calcIt)