$(document).ready(function () {
    
    $('.gallery-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
    })

    $('.slider-gallery').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        prevArrow: '<button class="btn with-bg icon icon-left-arrow prev"></button>',
        nextArrow: '<button class="btn with-bg icon icon-right-arrow next"></button>',
    })

    $('.slider-sertificate').slick({
        slidesToShow: 4,
        slidesToScroll: 2,
        adaptiveHeight: true,
        arrows: false
    })

    $('.popup-images-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        adaptiveHeight: true,
        prevArrow: '<button class="btn icon-left prev"></button>',
        nextArrow: '<button class="btn icon-right next"></button>'
    })

});