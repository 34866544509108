function openPopup(popup, video){
    if(typeof(popup) == 'string'){
        popup = $('.popup.' + popup)
    }

    popup.stop().fadeIn(400)
}

function closePopup(popup){
    if(typeof(popup) == 'string'){
        popup = $('.popup.' + popup)
    }

    popup.stop().fadeOut(400)
}

$('.order-btn').click(function(){
    openPopup('order-popup')
})

$(document).on('click', '.close-popup', function(){
    var elem = $(this),
        popup = elem.parents('.popup'),
        btn = popup.find('.btn')

    closePopup(popup)
    btn.removeAttr('disabled')
})

$('.item-cert-slide').click(function(){
    openPopup('slider-popup')
})

$('.close-slider-popup').click(function(){
    closePopup('slider-popup')
})

$('.open-popup-img').click(function(){
    openPopup($(this).data('popup'))
})

$('.close-popup-img').click(function(){
    closePopup($(this).data('popup'))
})

$('.open-popup-gal').click(function(){
    openPopup($(this).data('popup'))
})

$('.close-popup-gal').click(function(){
    closePopup($(this).data('popup'))
})